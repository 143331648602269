<template>
    <Layout>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 font-size-18">{{ $t(`pending bets`) }}</h4>
        </div>
        <div
            class="card data"
            style="border-top-left-radius: 0; border-top-right-radius: 0">
            <div class="card-header bg-light bg-soft filters">
                    <table class="table table-bordered table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1 w-100">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            disabled
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white opacity-50"></datepicker>
                                        <button
                                            class="btn btn-outline-info"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                     <b-form inline @submit.prevent="initList(1)">
                                        <div class="d-flex gap-1">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t(`enter keyword`)"
                                                v-model="filters.keyword" />
                                            <button
                                                :class="`btn btn-info ${
                                                    loading ? 'disabled' : ''
                                                }`"
                                                type="submit">
                                                {{ $t('search') }}
                                            </button>
                                            <button
                                                :class="`btn btn-danger ${
                                                    loading ? 'disabled' : ''
                                                }`"
                                                @click="resetFilter()">
                                                {{ $t('reset') }}
                                            </button>
                                        </div>
                                    </b-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            <div class="card-body pt-1">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="7"
                        :rows="50"
                        class="table-bordered table-striped"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    {{ $t('date') }}
                                </th>
                                <th scope="col">
                                    {{ $t('product') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game name') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game type') }}
                                </th>
                                <th scope="col">
                                    {{ $t('username') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('result time') }}
                                </th>
                                <th scope="col">
                                    {{ $t('payment amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('difference') }}
                                </th>
                                <th scope="col">
                                    {{ $t('balance') }}
                                </th>
                                <th scope="col">
                                    {{ $t('player points') }}
                                </th>
                                <th scope="col">
                                    {{ $t('agent commission') }}
                                </th>
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                                <th scope="col">
                                    {{ $t('details') }}
                                </th>
                                <!-- <th scope="col">
                                            {{ $t('asset log') }}
                                        </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    {{ r.vendor }}
                                </td>
                                <td class="text-center">
                                    {{ r.title }}
                                </td>
                                <td class="text-center">
                                    {{ $t(r.game) }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    'player'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    'player'
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi mdi-numeric-${r.user.player.level}-box level-${r.user.player.level} fs-5`"></i>
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td>
                                    {{ r.reference_debit.username }}
                                </td>
                                <td>
                                    {{ r.reference_debit.transaction.id }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.amount) }}
                                </td>
                                <td>
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-end text-danger">
                                    {{
                                        r.win_amount && r.status != 'cancel'
                                            ? numberString(r.win_amount)
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.difference) }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.player_asset_log
                                            ? numberString(
                                                  r.player_asset_log
                                                      .updated_value
                                              )
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.player_claim_rebate_amount
                                            ? `${numberStringDecimal(
                                                  r.player_claim_rebate_amount
                                              )}`
                                            : ''
                                    }}
                                    {{
                                        r.player_claim_commission_amount
                                            ? `(${numberStringDecimal(
                                                  r.player_claim_commission_amount
                                              )})`
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.agent_claim_commission_amount
                                            ? `${numberStringDecimal(
                                                  r.agent_claim_commission_amount
                                              )}`
                                            : ''
                                    }}
                                </td>
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-primary':
                                                r.status == 'pending',
                                            'text-danger': r.status == 'win',
                                            'text-info': r.status == 'lose',
                                            'text-secondary':
                                                r.status == 'cancel',
                                        }"
                                        >{{ $t(r.status) }}</span
                                    >
                                </td>
                                <td class="text-center">
                                     <div class="button-items">
                                        <button
                                            :disabled="view_loading"
                                            class="btn btn-outline-info btn-sm"
                                            @click="showViewModal(r._id)">
                                            {{ $t('view') }}
                                        </button>
                                        <button
                                            :disabled="view_loading"
                                            v-if="r.status == 'pending'"
                                            class="btn btn-outline-success btn-sm"
                                            @click="showResultModal(r)">
                                            {{ $t('update result') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <!-- @click="scroll('right')" -->
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="view_modal"
            @hidden="reset()"
            centered
            :title="$t(`details`)"
            title-class="font-18"
            body-class="pt-0"
            size="xl"
            hide-footer>
            <div class="loader-wrapper" v-if="view_loading">
                <b-spinner variant="dark"></b-spinner>
                <p class="fw-bold fs-5">{{ $t('loading') }}...</p>
            </div>
            <div class="bet-details-header">
                <div :class="`flex-fill`">
                    <table class="table table-sm table-bordered mb-0">
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('date') }}
                                </th>
                                <td style="width: 35%">
                                    {{ dateYmD(bet_detail.created_at, true) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 35%">
                                    <span
                                        :class="{
                                            'text-primary':
                                                bet_detail.status == 'pending',
                                            'text-danger':
                                                bet_detail.status == 'win',
                                            'text-info':
                                                bet_detail.status == 'lose',
                                            'text-secondary':
                                                bet_detail.status == 'cancel',
                                        }"
                                        >{{ $t(bet_detail.status) }}</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    {{ bet_detail.username }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.amount) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('game') }}
                                </th>
                                <td style="width: 35%">
                                    {{ $t(bet_detail.game) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('payment amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.win_amount) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="baccarat-cards" v-if="bet_detail.card_info">
                    <span
                        :class="`tie-indicator ${
                            bet_detail.card_info.outcome == 'Tie'
                                ? 'result'
                                : ''
                        }`"
                        >{{ $t('tie') }}</span
                    >
                    <div
                        :class="`text-start d-flex align-items-center gap-1 player ${
                            bet_detail.card_info.outcome == 'Player'
                                ? 'result'
                                : ''
                        }`">
                        <div class="side">
                            {{ $t('player') }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.player
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="score">
                            {{ bet_detail.card_info.player.score }}
                        </div>
                    </div>
                    <div
                        :class="`text-start d-flex align-items-center gap-1 banker ${
                            bet_detail.card_info.outcome == 'Banker'
                                ? 'result'
                                : ''
                        }`">
                        <div class="score">
                            {{ bet_detail.card_info.banker.score }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.banker
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="side">
                            {{ $t('banker') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" >
                <table
                    class="table table-bordered table-striped table-nowrap table-sm mb-0">
                    <thead v-show="bet_detail.agent_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('agent commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.agent_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.player_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_rebate.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player rebate') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in bet_detail.player_rebate" :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal
            v-model="result_modal"
            @hidden="reset()"
            centered
            :title="$t('update result')"
            title-class="font-18"
            hide-footer>
            <b-form>
                <table class="table table-bordered table-wrap table-form mb-2">
                    <tbody>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('username') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    <i
                                        :class="`mdi ${
                                            pl_result.user_type == 'online'
                                                ? 'mdi-web text-info'
                                                : pl_result.user_type ==
                                                  'offline'
                                                ? 'mdi-storefront text-success'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi ${
                                            pl_result.user_role == 'agent'
                                                ? 'mdi-account-tie text-danger'
                                                : pl_result.user_role ==
                                                  'player'
                                                ? 'mdi-account-cowboy-hat text-primary'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi mdi-numeric-${pl_result.user_level}-box level-${pl_result.user_level} fs-5`"></i>
                                    {{ pl_result.username }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('game type') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{ pl_result.game? $t(pl_result.game): '' }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('game name') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{ pl_result.game_title }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('bet amount') }}
                            </th>
                            <td>
                                <div class="mt-2">
                                    {{
                                        numberStringDecimal(
                                            pl_result.bet_amount
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('result') }}
                            </th>
                            <td>
                                <multiselect
                                    :placeholder="$t('select result')"
                                    v-model="pl_result.result"
                                    :options="result_options"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_result.result.$error,
                                    }">
                                    <template v-slot:singlelabel="{ value }">
                                        <span
                                            class="mlabel flex-fill ps-2"
                                            :class="{
                                                'text-danger':
                                                    value.value == 'win',
                                                'text-info':
                                                    value.value == 'lose',
                                                'text-secondary':
                                                    value.value == 'cancel',
                                            }"
                                            >{{ $t(value.value) }}</span
                                        >
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span
                                            :class="{
                                                'text-danger':
                                                    option.value == 'win',
                                                'text-info':
                                                    option.value == 'lose',
                                                'text-secondary':
                                                    option.value == 'cancel',
                                            }">
                                            {{ $t(option.value) }}
                                        </span>
                                    </template>
                                </multiselect>
                                <div
                                    v-for="(item, index) in v$.pl_result.result
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-light bg-soft">
                                {{ $t('win amount') }}
                            </th>
                            <td>
                                <VueNumberFormat
                                    class="form-control text-end rounded-end"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v$.pl_result.win_amount.$error,
                                    }"
                                    v-model:value="pl_result.win_amount"
                                    :options="{
                                        precision: 0,
                                        prefix: '',
                                        suffix: '',
                                        thousand: ',',
                                        acceptNegative: false,
                                        isInteger: true,
                                    }"></VueNumberFormat>
                                <div
                                    v-for="(item, index) in v$.pl_result
                                        .win_amount.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-evenly gap-1">
                    <button
                        class="btn btn-success flex-fill"
                        :class="{ disabled: loading }"
                        @click="updateResult('submit')">
                        {{ $t('submit') }}
                    </button>
                    <button
                        class="btn btn-danger flex-fill"
                        :class="{ disabled: loading }"
                        @click="result_modal = false">
                        {{ $t('cancel') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';

import { mapActions } from 'vuex';
import convert from '../../../../mixins/convert';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import ui from '../../../../mixins/ui';
import {
    requiredIf,
    required,
    minValue,
    maxValue,
    between,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: null,
                from: this.getOneYearAgo(),
                // to: new Date(),
                to: new Date(this.getKoreanTime()),
                limit: 50,
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            bet_detail: {
                _id: '',
                created_at: '',
                username: '',
                game: '',
                status: '',
                amount: '',
                win_amount: '',
                player_commissions: [],
                agent_commissions: [],
                player_rebate: [],
                card_info: null,
            },
            loading: false,
            view_loading: false,
            view_modal: false,

            hoverDivLeft: 0,
            hoverDivTop: 0,
            result_options: [
                { name: this.$t('win'), value: 'win' },
                { name: this.$t('lose'), value: 'lose' },
                { name: this.$t('cancel'), value: 'cancel' },
            ],
            result_modal: false,
            pl_result: {
                _id: null,
                game_title: null,
                game: null,
                bet_amount: null,
                username: null,
                user_role: null,
                user_level: null,
                user_type: null,
                result: null,
                win_amount: 0,
            },
        };
    },
    validations() {
        return {
            pl_result: {
                result: {
                    required: helpers.withMessage('required', required),
                },
                win_amount: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function (value) {
                            if (value == 0 && this.pl_result.result == 'win') {
                                return required(value);
                            }
                            return true;
                        })
                    ),
                    minValueValue: helpers.withMessage('required', minValue(0)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
            },
        };
    },
    watch: {
        
    },
    methods: {
        ...mapActions('bet', {
            betGetList: 'getList',
            betGetListTotal: 'getListTotal',
            betUpdate: 'update',
            betView: 'view',
            betSetResult: 'setResult',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                user: 'player',
                limit: this.filters.limit,
                vendor: 'all',
                status: 'pending',
                live: false,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                // to: this.dateYmD(this.filters.to) + ' 23:59:59',
                to: this.getKoreanTime(),
                page_no: p ? p : 1,
                search: this.filters.keyword ? this.filters.keyword : '',
            };

            this.loading = true;
            const data = await this.betGetList(pl);
            this.data.list = data.data.data;
            this.data.links = data.data.links;
            this.data.current_page = data.data.current_page;
            this.data.per_page = data.data.per_page;
            this.data.last_page = data.data.last_page;
            this.data.total = data.data.total;
            this.loading = false;
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheMonth());
            // this.filters.to = new Date();
            this.filters.keyword = null;
            this.initList(1);
        },
        showHoverDiv(event, id, index) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'block';
            this.hoverDivLeft = this.$refs.tableScroller.clientWidth / 2;
            this.hoverDivTop = event.pageY / 2;
        },
        hideHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'none';
        },
        async showViewModal(id) {
            if (this.view_loading) return;
            this.view_modal = true;
            this.view_loading = true;
            const res = await this.betView(id);
            if (res) {
                this.bet_detail._id = res._id;
                this.bet_detail.created_at = res.created_at;
                this.bet_detail.username = res.user.username;
                this.bet_detail.game = res.game;
                this.bet_detail.status = res.status;
                this.bet_detail.amount = res.amount;
                this.bet_detail.win_amount = res.win_amount;
                this.bet_detail.agent_commissions = res.agent_commissions;
                this.bet_detail.player_commissions = res.player_commissions;
                this.bet_detail.player_rebate = res.player_rebate;
            }
            this.view_loading = false;
        },
        reset() {
            this.bet_detail._id = '';
            this.bet_detail.created_at = '';
            this.bet_detail.username = '';
            this.bet_detail.game = '';
            this.bet_detail.status = '';
            this.bet_detail.amount = '';
            this.bet_detail.win_amount = '';
            this.bet_detail.agent_commissions = [];
            this.bet_detail.player_commissions = [];
            this.bet_detail.player_rebate = [];
            this.bet_detail.card_info = null;

            this.pl_result._id = null;
            this.pl_result.game_title = null;
            this.pl_result.game = null;
            this.pl_result.bet_amount = 0;
            this.pl_result.username = null;
            this.pl_result.user_role = null;
            this.pl_result.user_level = null;
            this.pl_result.user_type = null;
            this.pl_result.result = null;
            this.pl_result.win_amount = 0;
            this.submitted = true;
            this.v$.pl_result.$reset();
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                // this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                // this.filters.to = this.addDaysToDate(-1);
            }
            this.initList(1);
        },
        showResultModal(r) {
            this.result_modal = true;

            this.pl_result._id = r._id;
            this.pl_result.game_title = r.title;
            this.pl_result.game = r.game;
            this.pl_result.bet_amount = r.amount;
            this.pl_result.username = r.user.username;
            this.pl_result.user_role = r.user.role;
            this.pl_result.user_level = r.user.level;
            this.pl_result.user_type = r.user.type;
        },
         async updateResult(r) {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl_result.$touch();
            if (this.v$.pl_result.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: this.$t('set the result manually?'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then((result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('result', this.pl_result.result);
                    fd.append('win_amount', this.pl_result.win_amount);
                    const res = await this.betSetResult({
                        id: this.pl_result._id,
                        form_data: fd,
                    });
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: this.$t(res.data.message),
                        confirmButtonText: this.$t('ok'),
                    });
                    if (res.success) {
                        this.initList(1);
                        this.result_modal = false;
                        this.reset();
                    }
                }
            }
        },
    },
    mounted() {
        this.initList(1);
    },
};
</script>
